import { RouteLocation, Router } from 'vue-router'
import { ContentData, PageData } from '@rtl/api'
import { Store } from 'vuex'
import { AppState, MEASUREMENT_ACTION, MEASUREMENT_NAMESPACE } from '../store'

export function getMetaFromRaw (metaData: Array<{ key?: string, value?: string }> = []) {
  return metaData.reduce<{ title?: string, meta: Array<Record<string, string>> }>((res, meta) => {
    if (meta.key && meta.value) {
      if (meta.key === 'title') {
        res.title = meta.value
      } else {
        res.meta.push({
          [meta.key.startsWith('og:') || meta.key.startsWith('video:') ? 'property' : 'name']: meta.key,
          content: meta.value
        })
      }
    }
    return res
  }, { meta: [] })
}

export function generateMetaInfo (route: RouteLocation, router: Router, page?: PageData, content?: ContentData, store?: Store<AppState>) {
  const domain = 'https://rtl.hu'
  const link: Array<unknown> = []
  const script: { [key: string]: unknown }[] = []

  // links
  link.push({
    rel: 'canonical',
    href: `${domain}${router.resolve({ query: { oldal: route.query['oldal'] }}).href}`
  })

  // scripts
  script.push({
    vmid: 'logo-schema',
    type: 'application/ld+json',
    textContent: JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: domain,
      logo: 'https://cdn.rtl.hu/9f/59/image_818396c83981bbb75bd43e5f8cb2'
    })
  })

  if (typeof window === 'undefined' || !window?.location.hostname.startsWith('app.')) {
    const dispatchAdoScriptLoaded = () => {
      store?.dispatch(`${MEASUREMENT_NAMESPACE}/${MEASUREMENT_ACTION.ADO_SCRIPT_LOADED}`)
    }
    script.push({
      vmid: 'adocean',
      src: '//hu.adocean.pl/files/js/ado.js',
      async: true,
      defer: true,
      onload: () => dispatchAdoScriptLoaded(),
      onerror: () => dispatchAdoScriptLoaded()
    })
  }

  const airship = store?.getters.getEnv('AIRSHIP')
  if (airship) {
    script.push({
      vmid: 'airship',
      type: 'text/javascript',
      textContent: `
        // 86acbd31cd7c09cf30acb66d2fbedc91daa48b86:1659520575.5442133
        !function(n,r,e,t,c){var i,o="Promise"in n,u={then:function(){return u},catch:function(n){
        return n(new Error("Airship SDK Error: Unsupported browser")),u}},s=o?new Promise((function(n,r){i=function(e,t){e?r(e):n(t)}})):u
        ;s._async_setup=function(n){if(o)try{i(null,n(c))}catch(n){i(n)}},n[t]=s;var a=r.createElement("script");a.src=e,a.async=!0,a.id="_uasdk",
        a.rel=t,r.head.appendChild(a)}(window,document,'https://aswpsdkeu.com/notify/v1/ua-sdk.min.js',
          'UA', JSON.parse('${airship}'));
      `,
    })
  }

  if (page?.site) {
    script.push({
      vmid: 'series-schema',
      type: 'application/ld+json',
      textContent: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'TVSeries',
        name: page.site.name,
        description: page.site.parameters?.config?.description,
        image: (page.site.parameters?.appearance?.coverImageUrls as any)?.coverImageUrl,
        copyrightYear: page.site.parameters?.nmhhParams?.prodYear ? parseInt(page.site.parameters.nmhhParams.prodYear): undefined,
        director: page.site.parameters?.nmhhParams?.directorName ? {
          '@type': 'Person',
          name: page.site.parameters.nmhhParams.directorName
        } : undefined,
        containsSeason: page.site.seasons?.map(season => ({
          '@type': 'TVSeason',
          name: season
        }))
      })
    })
  }

  if (content) {
    const contentTitle = content.alternativeTitle || content.title
    const contentDescription = content.alternativeLead || content.lead || ''
    const contentReleaseDate = content.releaseDateRaw

    const images = []
    if (content.leadImageUrls?.leadImageUrl) {
      images.push(content.leadImageUrls.leadImageUrl)
    }

    script.push({
      vmid: 'article-schema',
      type: 'application/ld+json',
      textContent: JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'NewsArticle',
        headline: contentTitle,
        image: images,
        datePublished: contentReleaseDate,
        dateModified: content.modifiedTsRaw,
        author: content.authors?.map(author => ({
          '@type': 'Person',
          name: author.name
        })),
        video: content.params?.withVideo ? {
          '@type': 'VideoObject',
          name: contentTitle,
          description: contentDescription.replace(/<[^>]*>?/gm, '').trim(),
          thumbnailUrl: images,
          uploadDate: contentReleaseDate,
          duration: `PT${content.params?.duration || 0}S`,
          embedUrl: content.params?.embedVideoUrl
        } : undefined
      })
    })
  }

  return {
    title: 'RTL.hu',
    ...getMetaFromRaw(page?.metaTags),
    link,
    script
  }
}
